<template>
  <b-card no-body>
    <b-card-header class="pt-1 pb-1">
      <h5 class="mb-0 flex-grow-1 text-primary">
        Bộ lọc
      </h5>
      <div class="d-flex gap-1 align-items-center">
        <b-button
          v-b-tooltip.hover.bottom="'Làm mới bộ lọc'"
          variant="flat-primary"
          size="sm"
          class="p-0"
          @click="handleClearSearch"
        >
          <feather-icon
            :icon="searching ? 'SettingsIcon' : 'XSquareIcon'"
            size="24"
            class="cursor-pointer"
            :class="searching ? 'spinner' : ''"
          />
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          @click="handleSearch"
        >
          Tìm kiếm
        </b-button>
      </div>
    </b-card-header>
    <div class="card-body">
      <b-row>
        <b-col
          cols="12"
          lg="4"
        >
          <!--              tỉnh-->
          <dynamic-form
            :id="'filter-form-tinh'"
            :value="value.province_id"
            :type="'select'"
            :label="'Tỉnh'"
            value-field="id"
            :class="'form-group-hor'"
            :options="PROVINCE"
            @update-value="val => value.province_id = val"
          />

          <!--              quận huyện-->
          <dynamic-form
            :id="'filter-form-quanhuyen'"
            :value="value.district_id_in"
            :type="'multi-select'"
            :label="'Quận/Huyện'"
            value-field="id"
            text-field="name"
            :class="'form-group-hor'"
            :options="districts"
            :select-default="false"
            @update-value="val => value.district_id_in = val"
          />

          <!--              Phường/Xã-->
          <dynamic-form
            v-if="!value.district_id_in || value.district_id_in.length < 2"
            :id="'filter-form-phuongxa'"
            :value="value.ward_id_in"
            :type="'multi-select'"
            :label="'Phường/Xã'"
            value-field="id"
            text-field="name"
            :class="'form-group-hor'"
            :options="wardsByDistricts"
            :select-default="false"
            @update-value="val => value.ward_id_in = val"
          />

          <!--              Đường-->
          <dynamic-form
            :id="'filter-form-duong'"
            :value="value.street_name_in"
            :type="'multi-select'"
            :label="'Đường'"
            value-field="name"
            text-field="name"
            :class="'form-group-hor'"
            :options="streetsByDistricts"
            :select-default="false"
            @update-value="val => value.street_name_in = val"
          />

        </b-col>
        <b-col
          cols="12"
          lg="4"
        >

          <!--              Hướng-->
          <dynamic-form
            :id="'product-form-direction'"
            :value="value.direction"
            :type="'select'"
            :label="'Hướng'"
            :class="'form-group-hor'"
            :select-default="false"
            :options="OFFICE_DIRECTION"
            @update-value="val => value.direction = val"
          />

          <!--              Hạng-->
          <dynamic-form
            :id="'product-form-class'"
            :value="value.class"
            :type="'select'"
            :label="'Hạng'"
            :class="'form-group-hor'"
            :select-default="false"
            :options="OFFICE_RANK"
            @update-value="val => value.class = val"
          />

          <!--            đặc biệt-->
          <dynamic-form
            :id="'filter-form-dacbiet'"
            :value="value.is_rent_all_apartment"
            :type="'checkbox'"
            :label="'Đặc biệt'"
            :sub-label="'Thuê nguyên căn'"
            :class="'form-group-hor'"
            @update-value="val => value.is_rent_all_apartment = (val ? 1 : '')"
          />

        </b-col>

        <b-col
          cols="12"
          lg="4"
        >

          <!--            Diện tích-->
          <!--          <dynamic-form-->
          <!--            :id="'filter-form-acreage'"-->
          <!--            :value="value.acreage"-->
          <!--            :type="'number'"-->
          <!--            placeholder="Tổi thiểu"-->
          <!--            :label="'Diện tích'"-->
          <!--            :class="'form-group-hor'"-->
          <!--            append-text="≤"-->
          <!--            @update-value="val => value.acreage = val"-->
          <!--          />-->

          <!--            Diện tích-->
          <div class="form-group-hor mb-1">
            <label>Diện tích</label>
            <div class="grid-2-columns">
              <b-input-group append="≤">
                <b-form-input
                  v-model="value.acreage"
                  placeholder="Tối thiểu"
                />
              </b-input-group>
              <b-input-group prepend="≤">
                <b-form-input
                  v-model="value.max_acreage"
                  placeholder="Tối đa"
                />
              </b-input-group>
            </div>
          </div>

          <!--            Giá-->
          <div class="form-group-hor mb-1">
            <label>Giá (USD/m<sup>2</sup>)</label>
            <div class="grid-2-columns">
              <b-input-group append="≤">
                <b-form-input
                  v-model="value.price_from"
                  placeholder="Tối thiểu"
                />
              </b-input-group>
              <b-input-group prepend="≤">
                <b-form-input
                  v-model="value.price_to"
                  placeholder="Tối đa"
                />
              </b-input-group>
            </div>
          </div>

          <!--            Tên-->
          <dynamic-form
            :id="'filter-form-acreage'"
            :value="value.q"
            :type="'text'"
            placeholder="Tên"
            :label="'Tên'"
            :class="'form-group-hor'"
            @update-value="val => value.q = val"
          />

        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCol, BFormInput, BInputGroup, BRow, VBTooltip,
} from 'bootstrap-vue'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import useProductOfficeList from '@/views/product-office/list/useProductOfficeList'
import { ref, watch } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    DynamicForm,
    BButton,
    BCardHeader,
    BFormInput,
    BInputGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const searching = ref(null)

    const {
      wardsByDistricts, streetsByDistricts, districts, users, productListMenuType, productMenuType, productMenuTypeVI,
    } = useProductOfficeList()

    const handleSearch = () => {
      emit('update:loading', true)
      if (searching.value) {
        clearTimeout(searching.value)
        searching.value = null
      }
      // new search delay
      searching.value = setTimeout(() => {
        emit('search')
        // waiting for vue update -> parent -> child(here)
        setTimeout(() => {
          clearTimeout(searching.value)
          searching.value = null
        }, 50)
      }, 500)
    }

    const handleClearSearch = () => {
      searching.value = null
      emit('clear-filter')
    }

    watch(props.value, () => {
      handleSearch()
    })
    watch([productListMenuType, productMenuType], () => {
      handleClearSearch()
    })

    return {
      searching,
      districts,
      productListMenuType,
      users,
      wardsByDistricts,
      productMenuType,
      productMenuTypeVI,
      streetsByDistricts,
      handleSearch,
      handleClearSearch,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/quill.scss';
</style>
